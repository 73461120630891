import React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import Layout from "../components/layout"
import kebabCase from "lodash/kebabCase"
import SEO from "../components/seo"
import Share from "../components/share"
import Tag from "../components/tag"
import RevueSubscribeFooter from "../components/revue-subscribe-footer"

export default function BlogPost({ data }) {
    const { pathname } = useLocation()
    const post = data.markdownRemark
    const title = data.markdownRemark.frontmatter.title
    const image = post.frontmatter.image
    ? post.frontmatter.image.childImageSharp.resize
    : null
    //function sayHello() {
      //var popup = document.getElementById("myPopup");
      //popup.classList.toggle("hidden");
    //}

    return (

      <Layout>
                <SEO
                  title={title}
                  description={post.frontmatter.description || post.excerpt}
                  image={image}
                />
          <Helmet title={title} />
      <div class="h-20 md:h-16 ">
</div>
<div class="bg-primary-100 shadow-lg font-inter rounded-lg sm:ml-16 sm:mr-16 px-6 md:mx-36 py-6 lg:px- text-left">
        <div class="flex justify-between items-center">
            <div class="text-gray-600 font-normal">
                {data.markdownRemark.frontmatter.displaydate}
            </div>
            <div class="uppercase text-blue-900 font-bold hover:text-gray-200 hover:bg-primary-300 rounded p-1">
                <Share title={title} summary={data.markdownRemark.frontmatter.condensed} pathname = {pathname} url={data.site.siteMetadata.url}/>
            </div>

        </div>
        <div>
            <div class="text-2xl -ml-1 md:text-3xl font-semibold rounded px-1 text-primary-900 pl-1" > 
                {data.markdownRemark.frontmatter.title}
            </div>
        </div>
        <div class="flex justify items-center flex-wrap ">
                {data.markdownRemark.frontmatter.tags.map(tag => (
          <Tag class="p-1 mr-2 text-sm lowercase rounded-md text-blue-900 font-semibold shadow-inner2 hover:bg-gray-800 hover:text-white" key={tag.fieldValue}>
            <Link class="uppercase" to={`/tags/${kebabCase(tag)}/`}>
              {tag} 
            </Link>
          </Tag>

        ))}


        </div>

        <div class="mt-2 text-md text-primary-900 tracking-normal leading-6 font-normal" dangerouslySetInnerHTML={{ __html: post.html }} />
              
    
  </div>
  <RevueSubscribeFooter />
 
  
        
      </Layout>
    )
  }
  export const query = graphql`
    query($slug: String!) {
      markdownRemark(fields: { slug: { eq: $slug } }) {
        html
        frontmatter {
            title
            date
            condensed
            type
            published
            displaydate
            tags
        }
        excerpt
      }
      allMarkdownRemark(limit: 2000) {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
      }
      site {
        siteMetadata {
          url
          twitterUsername
          title
          siteUrl
          keywords
          image
          description
          titleTemplate
        }
      }

    }
  `